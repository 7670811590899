import capi from '../capi/instance';
import { CommonRes } from 'types/index';
import { CommonListRes } from './asset';

type GetCustomUploadCredentialsReq = {};
export type ServiceAssetMap =
  | 'ImageAsset'
  | 'TimbreAsset'
  | 'SmallSampleGeneral2DImageAsset'
  | 'SmallSample4K2DImageAsset'
  | 'SmallSamplePhoto2DImageAsset'
  | 'ZeroShotTimbreAsset';
type AssetItem = {
  serviceType: string;
  quotaNum: number;
};
interface GetCustomUploadCredentialsRes extends CommonRes {
  pathPrefix: string;
  startTime: number;
  expiredTime: number;
  credentials: {
    token: string;
    secretId: string;
    secretKey: string;
  };
}

export interface CustomizeAssetReq {
  anchorName: string;
  makeType: string;
  identityCosUrl?: string;
  identityWrittenCosUrl?: string;
  materialCosUrl: string;
  isHaveBackground: boolean;
  sexType: string;
  notes: string;
}

interface GetCustomUploadCredentialsRes extends CommonRes {
  taskId: string;
}

type GetCustomRemainQuotaReq = {
  serviceTypeList?: ServiceAssetMap[];
};

interface GetCustomRemainQuotaRes extends CommonRes {
  imageRemainQuota: number;
  voiceRemainQuota: number;
  generalImageRemainQuota: number;
  quotaServiceAssetList: AssetItem[];
}

type CheckCustomAnchorNameRepeatReq = {
  imageAnchorName?: string[];
  voiceAnchorName?: string[];
  photoName?: string[];
  customTaskId?: number;
  customType?: number;
};

interface CheckCustomAnchorNameRepeatRes extends CommonRes {
  repeatImageAnchorName: string[];
  repeatVoiceAnchorName: string[];
  repeatPhotoAnchorName: string[];
}

type BatchMakeReq = {
  name: string;
  sexType: string;
  identityCosUrl?: string; // 手持身份证视频地址
  identityWrittenCosUrl?: string; // pdf文件授权地址
  notes?: string; // 备注
  isHaveBackground?: boolean; // 是否去绿幕,0：需要，1：不需要
  videoCosUrl?: string[]; // 上传视频文件
  voiceCosUrl?: string; // 上传音频文件
  textDriver?: string;
  voiceDriverCosFile?: string;
  makeType: string[];
  materialCosUrl?: string[];
  serviceProtocol?: boolean;
  audioTaskId?: number;
  audioId?: string;
  recordType?: number;
  timbreInfo?: TimbreInfo;
  resubmitId?: number;
  photoVersion?: number;
  zeroshortLang?: string;
};

type TimbreInfo = {
  timbreAssetsType?: number; // 音色资产类型；1-精品，2-定制
  isWithTimbre?: boolean; // 是否使用视频素材的音频制作demo，形象定制时才有
  timbreCode?: string; // 形象定制时制作demo的音色code
  speed?: string; // 指定音色的语速
};

type BatchMakeRes = CommonRes;

type ResubmitMaterialReq = {
  customTaskId: number;
  sexType: string;
  identityCosUrl?: string;
  identityWrittenCosUrl?: string;
  notes?: string;
  image?: string;
  voice?: string;
  name: string;
  isHaveBackground?: number;
  textDriver?: string;
  voiceDriverCosFile?: string;
  serviceProtocol?: boolean;
  materialCosUrl?: string;
  audioTaskId?: number;
  audioId?: string;
  recordType?: number;
  photoVersion?: number;
};

type ResubmitMaterialPhotoReq = {
  customTaskId?: number;
  sexType: string;
  image: string;
  name: string;
  audioTaskId?: number;
};

type ResubmitMaterialRes = CommonRes;

// 获取上传素材的cos密钥
export const getCustomUploadCredentials = async () => {
  const res = await capi<GetCustomUploadCredentialsReq, GetCustomUploadCredentialsRes>(
    'GetCustomUploadCredentials',
    {},
  );
  return res;
};

// 定制接口
export const saveCustomizeAsset = async (data: CustomizeAssetReq) => {
  const res = await capi<CustomizeAssetReq, CommonRes>('CustomizeAsset', data);
  return res;
};

// 查询定制剩余配额
// export const getCustomRemainQuota = async (data: GetCustomRemainQuotaReq) => {
//   const res = await capi<GetCustomRemainQuotaReq, GetCustomRemainQuotaRes>('GetCustomRemainQuota', data);
//   return res;
// };
export const getCustomRemainQuota = async (data: GetCustomRemainQuotaReq) => {
  const res = await capi<GetCustomRemainQuotaReq, GetCustomRemainQuotaRes>('DescribeCustomRemainQuota', data);
  return res;
};

// 检查主播名称是否重复
export const checkCustomAnchorNameRepeat = async (data: CheckCustomAnchorNameRepeatReq) => {
  const res = await capi<CheckCustomAnchorNameRepeatReq, CheckCustomAnchorNameRepeatRes>(
    'CheckCustomAnchorNameRepeat',
    data,
  );
  return res;
};

export interface GetCustomTaskListReq {
  customType: number;
  status?: {
    stage: string;
    state: number[];
  }[];
  createStartTime?: string;
  createEndTime?: string;
  taskId?: string;
  name?: string;
  pageNumber: number;
  pageSize: number;
}

export interface StageItem {
  stage: 'SUBMIT' | 'AUDIT' | 'PROCESS' | 'TRAIN' | 'CONFIRM' | 'END';
  endTime: string;
  processStatus: number;
  remark: string;
}

export interface DeliveryImage {
  resourceId: number; // 资源ID
  anchorName: string; // 主播名称
  anchorCode: string; // 主播Code
  virtualmanType: string; // 形象类型
  virtualmanTypeCode: string; // 形象类型名称
  clothesName: string; // 服装名称
  poseName: string; // 姿态名称
  resolution: string; // 分辨率
  headerImage: string; // 头像图片
  poseImage: string; // 姿态图片
}

export interface Delivery {
  deliveryId: number;
  deliveryNo: string;
  deliveryStatus: number; // 0-草稿状态, 1-待客户确认，2-待授权，3-授权完成, 4-交付完成，5-交付拒绝
  deliveryType: number;
  resourceName: string;
  resourceCode: string;
  resourceType: number; // 1-形象，2-声音
  demoURL: string;
  createTime: string;
  deliveryTime: string;
  confirmTime: string;
  effectiveTime: string;
  imageList: DeliveryImage[];
}

export interface CustomTaskItem {
  /**
   * 自定义类型. 0:2D小样本，1:小样本音色复制
   */
  customType: number;
  workOrderId: number;
  customTaskId: string;
  /**
   * 是否扣绿，0需要，1不需要
   */
  isHaveBackground: number;
  sexType: 'MALE' | 'FEMALE';
  originalMaterial: string;
  identityCosUrl: string;
  identityWrittenCosUrl: string;
  name: string;
  anchorName: string;
  stageList: StageItem[];
  failReason: string;
  audioTaskFailMsg: string;
  audioTaskId: string;
  failCode: number;
  createTime: string;
  recordType?: number;
  audioId?: string;
  timbreInfo?: TimbreInfo;
  resubmitId?: number;
  removeBackground?: number;
  photoVersion?: number;
}

interface GetCustomTaskListRes extends CommonRes {
  taskList: CustomTaskItem[];
  totalCount: number;
}
// 查询定制任务
export const getCustomTaskList = async (data: GetCustomTaskListReq) => {
  const res = await capi<GetCustomTaskListReq, GetCustomTaskListRes>('DescribeCustomTaskList', data);
  return res;
};

interface DescribeDeliveryListRsp extends CommonRes {
  deliveryList: Delivery[];
  total: number;
}
export interface DescribeDeliveryListReq {
  deliveryStatus: string;
  deliveryNo: string;
  deliveryType: number; // 1:3d写实，2:3d卡通，3:3d半写实，4:2d专属
  pageSize: number;
  pageNumber: number;
}
// 查询3D定制任务
export const getDeliveryList = async (data: DescribeDeliveryListReq) => {
  const res = await capi<DescribeDeliveryListReq, DescribeDeliveryListRsp>('DescribeDeliveryList', data);
  return res;
};

export interface ConfirmPushReq {
  status: number; // 0：同意，1：驳回
  reason?: string; // 驳回理由
  isWithTimbre?: boolean; // 是否发布音色
  deliveryId: number; // 交付单ID(无工单场景)
}
// 训练完成去确认+上架
export const confirmPush = async (data: ConfirmPushReq) => {
  const res = await capi<ConfirmPushReq, CommonRes>('ConfirmPush', data);
  return res;
};

interface GetCustomTaskResultReq {
  customTaskId: number;
}

export interface GetCustomTaskResultRes {
  timbreKey: string;
  trainMaterial: string[];
  demoUrl: string[];
  isHaveBackground: boolean;
  name: string;
  sexType: 'MALE' | 'FEMALE';
  customType: number;
  materialList: {
    cosUrl: string;
    name: string;
    isPlaying?: boolean;
  }[];
  recordList: {
    startTime: string;
    record: string[];
    demoList: DemoItem;
  }[];
  trainResult: string[];
  rejectReason: string;
  resultList: DemoItem[];
}

export type DemoItem = {
  driverType: 'text_drive_mp4' | 'audio_drive_webm' | 'pv'; // 驱动方式，文本驱动，音频驱动
  isZeroShort: boolean; // 是否包含一句话音色
  timbreName?: string; // 音色名称
  speed?: string; // 音色的语速
  videoUrl: string[]; // 视频链接
  demoTimbreType: 'specify' | 'specify_voice' | 'zero_short' | 'image_origin';
};

type DescribeDemoFileReq = {
  demoType?: number;
};
export type AdditionalItem = { key: string; value: string };
export interface DescribeDemoFileRes {
  renewUrl?: string; // 去续费地址
  guideUrl?: string; // 完整指引
  dictateEmpowerUrl?: string; // 口述版-形象授权书
  writtenEmpowerUrl?: string; // 《书面版-形象授权书》
  empowerDemoVideoUrl?: string; // 身份授权video示例
  empowerDemoPdfUrl?: string; // 身份授权pdf示例
  sampleDictateDocUrl?: string; // 《小样本口播参考文稿》
  imageDemoVideoUrl?: string; // 形象素材示例视频
  voiceDemoZipUrl?: string; // 音频文件示例
  defaultSsmlText?: string; // 文本驱动-默认ssml文本
  serviceProtocol?: string; // 服务协议
  serviceArticle?: string; // 服务条款
  alphaChannelVideoGuide?: string; // alpha通道视频指南
  additionalList?: AdditionalItem[]; // alpha通道视频指南
}

// 查询定制任务训练结果
export const getCustomTaskResult = async (data: GetCustomTaskResultReq) => {
  const res = await capi<GetCustomTaskResultReq, GetCustomTaskResultRes>('DescribeTrainResult', data);
  return res;
};

interface ConfirmTrainReq {
  customTaskId: number;
  /**
   * 0：同意，1：驳回
   */
  status: number;
  reason?: string;
  isWithTimbre?: boolean;
}

// 认可训练结果
export const confirmTrain = async (data: ConfirmTrainReq) => {
  const res = await capi<ConfirmTrainReq, {}>('ConfirmPush', data);
  return res;
};

// 批量上传
export const batchMake = async (data: BatchMakeReq) => {
  const res = await capi<BatchMakeReq, BatchMakeRes>('BatchMake', data);
  return res;
};

// 重新提交
export const resubmitMaterial = async (data: ResubmitMaterialReq) => {
  const res = await capi<ResubmitMaterialReq, ResubmitMaterialRes>('ResubmitMaterial', data);
  return res;
};

// // 批量上传
// export const batchPhotoMake = async (data: ResubmitMaterialPhotoReq) => {
//   const res = await capi<ResubmitMaterialPhotoReq, BatchMakeRes>('BatchMake', data);
//   return res;
// };

// // 重新提交
// export const resubmitPhotoMaterial = async (data: ResubmitMaterialPhotoReq) => {
//   const res = await capi<ResubmitMaterialPhotoReq, ResubmitMaterialRes>('ResubmitMaterial', data);
//   return res;
// };

// // 查询示例文件接口
// export const describeDemoFile = async (data: DescribeDemoFileReq) => {
//   const res = await capi<null, DescribeDemoFileRes>('DescribeDemoFile', null);
//   return res;
// };
// 查询示例文件接口
export const describeDemoFile = async (data: DescribeDemoFileReq) => {
  const res = await capi<DescribeDemoFileReq, DescribeDemoFileRes>('DescribeDemoFile', data);
  return res;
};

type VoiceRecordListReq = {
  pageNumber: number;
  pageSize: number;
  status: number; // 0：录制中，1：录制完成，2：已提交定制，3：已停用
};

export interface VoiceRecordItem {
  taskId: number;
  recordName: string;
  /**
   * 0：录制中，1：录制完成，2：已提交定制，3：已停用
   */
  taskStatus: number;
  sex: string; // MALE,FEMALE
  note: string;
  customTaskId: number;
  /**
   * 0：定制中，1：已交付，2：已结束
   */
  customTaskStatus: number;
  createTime: string;
  disableTime: string;
  uin: string;
  cosUrl: string;
}
export interface VoiceRecordListStatics {
  /**
   * 音色定制额度
   */
  timbreAssetNum: number;
  /**
   * 录制中的任务数
   */
  recordingNum: number;
  /**
   * 完成录制的任务数
   */
  finishNum: number;
  /**
   * 子账号录制中的任务数
   */
  subRecordingNum: number;
  /**
   * 子账号录制完成的任务数
   */
  subFinishNum: number;
}
interface VoiceRecordListRes extends VoiceRecordListStatics {
  total: number;
  taskList: VoiceRecordItem[];
}

/**
 * 获取录音任务
 */
export const getVoiceRecordList = async (data: VoiceRecordListReq) => {
  const res = await capi<VoiceRecordListReq, VoiceRecordListRes>('DescribeVoiceRecordList', data);
  return res;
};

export type CreateVoiceRecordReq = {
  recordName: string;
  sexType: string;
  note: string;
  customType?: number;
};
type CreateVoiceRecordRes = {
  taskId: string;
};

/**
 * 创建录音任务
 */
export const createVoiceRecord = async (data: CreateVoiceRecordReq) => {
  const res = await capi<CreateVoiceRecordReq, CreateVoiceRecordRes>('CreateVoiceRecordTask', data);
  return res;
};

type DisableVoiceRecordReq = {
  taskId: number;
};
type DisableVoiceRecordRes = {};

/**
 * 停用录音任务
 */
export const disableVoiceRecord = async (data: DisableVoiceRecordReq) => {
  const res = await capi<DisableVoiceRecordReq, DisableVoiceRecordRes>('DisableVoiceRecord', data);
  return res;
};

/**
 * 上传检测的音色素材
 */
type CheckAudioReq = {
  audioFile: string;
};
type CheckAudioRes = {
  taskId: string;
};
export const checkAudio = async (data: CheckAudioReq) => {
  const res = await capi<CheckAudioReq, CheckAudioRes>('CheckAudio', data);
  return res;
};

type GetAudioRecordListReq = {
  taskId: number;
};
export type GetAudioRecordItem = { stage: number; file: string; audioId: string };
export type GetAudioRecordListRes = {
  list: GetAudioRecordItem[];
  /**
   * 0 处理中，1成功，2失败
   */
  status: number;
};
/**
 * 获取检测记录列表
 */
export const getAudioRecordList = async (data: GetAudioRecordListReq) => {
  const res = await capi<GetAudioRecordListReq, GetAudioRecordListRes>('GetAudioRecordList', data);
  return res;
};

type GetAudioCheckResultReq = {
  taskId: number;
  stage: number;
};
export type AlignSentence = {
  startMs: number;
  endMs: number;
  sentence: string;
};
export type IndicatorType = {
  value: number;
  qualifiedRange: number[];
  description: string;
};
type GetAudioCheckResultRes = {
  audioUrl: string;
  /**
   * 参考文本，可选，不填表示不需要文本准确性指标
   */
  referenceText: string;
  /**
   * ASR识别出的文本，可选，不填表示不需要文本准确性指标
   */
  recognizedText: string;
  resultDetail: {
    /**
     * 信噪比
     */
    sNR: IndicatorType;
    /**
     * 混响指数
     */
    c50: IndicatorType;
    /**
     * 截幅
     */
    clipping: IndicatorType;
    /**
     * 有效时长
     */
    duration: IndicatorType;
    wer: IndicatorType;
    alignDetails: {
      detail: string[];
    }[];
    sentences: AlignSentence[];
  };
};
/**
 * 获取检测结果
 */
export const getAudioCheckResult = async (data: GetAudioCheckResultReq) => {
  const res = await capi<GetAudioCheckResultReq, GetAudioCheckResultRes>('GetAudioCheckResult', data);
  return res;
};

/**
 * 获取图片、音色检测结果
 */
// 检测状态Checking/Qualified/Unqualified，"Checking": 检测中；"Qualified": 合格，"Unqualified"：不合格
export type CheckState = 'Checking' | 'Qualified' | 'Unqualified';
// 检测类型Image/Voice，"Image"：形象定制； "Voice": 音色定制
export type CheckType = 'Image' | 'Voice';
export type CheckedMaterial = {
  type: CheckType;
  url: string;
};
export type ImageCheckResult = {
  greenRatio: number; // 绿色比例
  greenImage: string; // 背景分割彩色图
  result: string; // 结果：合格/不合格
};
type GetCheckMaterialResultReq = {
  taskId: number;
  stage: number;
};
type GetCheckMaterialResultRes = {
  material: CheckedMaterial;
  audioResult: GetAudioCheckResultRes;
  imageResult: ImageCheckResult;
};
export const getCheckMaterialResult = async (data: GetCheckMaterialResultReq) => {
  const res = await capi<GetCheckMaterialResultReq, GetCheckMaterialResultRes>('GetCheckMaterialResult', data);
  return res;
};

// 提交素材检测任务
type SubmitCheckMaterialTaskReq = {
  materials: CheckedMaterial[]; //  待检测的素材
};
type SubmitCheckMaterialTaskRes = {};
export const submitCheckMaterialTask = async (data: SubmitCheckMaterialTaskReq) => {
  const res = await capi<SubmitCheckMaterialTaskReq, SubmitCheckMaterialTaskRes>('SubmitCheckMaterialTask', data);
  return res;
};
// 获取检测素材列表
export type CheckMaterialState = {
  material: CheckedMaterial; // 检测的素材信息
  checkState: CheckState; // 检测状态
  stateDetail: string; // 检测详情
  time: string; // 创建时间
  stage: number; // 素材编号
  taskId: number;
};
export type GetCheckMaterialListReq = {
  offset: number; // 起始项
  limit: number; // 数量
  checkTypes?: CheckType[];
  checkState?: CheckState[];
  orderByTimeDesc?: boolean; // 是否按时间倒序排序
};
type GetCheckMaterialListRes = CommonListRes & {
  list: CheckMaterialState[];
  total: number;
};
export const getCheckMaterialList = async (data: GetCheckMaterialListReq) => {
  const res = await capi<GetCheckMaterialListReq, GetCheckMaterialListRes>('GetCheckMaterialList', data);
  return res;
};

// 删除检测素材
type RemoveCheckMaterialReq = {
  taskId: number; // 检测任务id
  stage: number; // 检测素材编号
};
type RemoveCheckMaterialRes = {
  state: string; // 删除状态
  errorMsg: string; // 删除错误信息
};
export const removeCheckMaterial = async (data: RemoveCheckMaterialReq) => {
  const res = await capi<RemoveCheckMaterialReq, RemoveCheckMaterialRes>('RemoveCheckMaterial', data);
  return res;
};
// 创建zeroshot音质检测接口
type DetectAudioQualityReq = {
  audioUrl: string;
  language: string;
};
type DetectAudioQualityRes = {
  taskId: string;
};
export const detectAudioQuality = async (data: DetectAudioQualityReq) => {
  const res = await capi<DetectAudioQualityReq, DetectAudioQualityRes>('DetectAudioQuality', data);
  return res;
};
// 获取zeroshot录音检测结果
type GetDetectProgressReq = {
  taskId: string; // 检测任务id
};
type GetDetectProgressRes = CommonRes & {
  audioId: string;
  detectionCode: number; //	检测code
  detectionMsg: string; //	检测提示信息
  taskState: string; //	任务状态
  errorMsg: string;
};
export const getDetectProgress = async (data: GetDetectProgressReq) => {
  const res = await capi<GetDetectProgressReq, GetDetectProgressRes>('GetDetectProgress', data);
  return res;
};

// 提交人脸识别任务
type FaceDetectReq = {
  imageUrl: string;
  checkerVersion: number;
  requestId: string;
};
type FaceDetectRes = CommonRes & {
  taskId: string;
};
export const faceDetect = async (data: FaceDetectReq) => {
  const res = await capi<FaceDetectReq, FaceDetectRes>('FaceDetect', data);
  return res;
};
// 提交人脸识别任务
type CropPosition = {
  left: number;
  top: number;
  width: number;
  height: number;
};
export type FacedetectstateType = {
  errorMsg: string;
  isPassDetect: boolean;
  taskId: string;
  taskState: 'SUCCESS' | 'PROCESSING';
  faces: {
    box: CropPosition;
    maxCrop: CropPosition;
    minCrop: CropPosition;
  }[];
};
type GetFaceDetectStateReq = {
  taskId?: string;
};
type GetFaceDetectStateRes = CommonRes & FacedetectstateType;
export const getFaceDetectState = async (data: GetFaceDetectStateReq) => {
  const res = await capi<GetFaceDetectStateReq, GetFaceDetectStateRes>('GetFaceDetectState', data);
  return res;
};
